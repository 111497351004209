var popup_close_callback = null

var mbox_callback = null

var confirm_callback_yes = null
var confirm_callback_no = null
var closer_timer = null
var closer_ticks = 0

var autoclose = {
	click: true,
	press: true
}

/**
 * Shows a popup and loads content into it
 * @param {string} title - Text in the popup header
 * @param {string} url - URL to load content from
 * @param {object} data - Key - value pairs to pass as POST with the content request
 * @param {function} callback - Function to run after the content has been loaded
 */
function popup(title, url, data, callback) {
	// Add site URL if not full URL received
	if (url.substr(0, 8) !== 'https://') {
		url = base_url + url
	}

	$('#popup-title').html(title)
	$('#popup-body').html('<p class="text-center p-t-2 p-b-2">' + icon('fa-solid fa-spinner fa-pulse fa-2x fa-fw') + '</p>')
	$('#popup-body').load(url, data, function() {
		if (typeof callback !== 'undefined') {
			callback()
		}
	})
	popup_show()
}

/**
 * Sets the width of the popup
 * @param {number} width - Width to set
 */
function popup_width(width) {
	$('#popup-container').width(width)
	$('#popup-container').css('max-width', '100%')
}

/**
 * Sets the height of the popup
 * @param {number} height - Height to set
 */
function popup_height(height) {
	$('#popup-body').height(height)
}

/**
 * Set buttons to be placed int he popup footer
 * @param {array} buttons - List of buttons to put into the footer
 */
function popup_buttons(buttons) {
	var buttons_html = ''

	if (typeof buttons === 'undefined') {
		buttons = [ ]
	}

	if (typeof buttons.length !== 'undefined') {
		for (var i = 0; i < buttons.length; i++) {
			var button = buttons[i]
			var html
			var classes = 'btn'
			var onclick = ''

			if (typeof button.click !== 'undefined') {
				onclick += button.click

				if (onclick.substr(-1, 1) !== ';') {
					onclick += ';'
				}
			}
			if (typeof button.close !== 'undefined' && button.close) {
				if (onclick !== '') {
					html += ' '
				}
				onclick += 'popup_close();"'
			}
			if (typeof button.class !== 'undefined') {
				classes += ' ' + button.class
			}

			html = '<button type="button"'
			if (onclick !== '') {
				html += ' onclick="' + onclick + '"'
			}
			html += ' class="' + classes + '">'
			if (typeof button.icon !== 'undefined') {
				html += icon(button.icon) + ' '
			}
			if (typeof button.text !== 'undefined') {
				html += button.text
			}
			html += "</button>\n"

			buttons_html += html
		}
	}

	$('#popup-buttons').html(buttons_html)
}

/**
 * Displays the popup
 */
function popup_show() {
	$('#popup-close-progressbar').hide()
	//$('#popup').modal('show')
	popup_element.show()
}

/**
 * Immediately closes the popup or schedules the action
 * @param {number} wait_seconds - Seconds to wait before closing (defaults to 0)
 * @param {function} callback - Function to run when closing
 */
function popup_close(wait_seconds, callback) {
	var wait = 0

	// Dismiss any timed closings
	closer_ticks = 0
	popup_close_callback = null
	if (closer_timer !== null) {
		clearInterval(closer_timer)
	}

	// Set delay of closing
	if (typeof wait_seconds !== 'undefined') {
		wait = parseInt(wait_seconds, 10) * 1000
	}

	// Set callback when closing
	if (typeof callback === 'function') {
		popup_close_callback = callback
	}

	// Schedule closing
	if (wait > 0) {
		$('#popup-close-progressbar').show()
		$('#popup-close-progress').width('0%')
		closer_timer = setInterval(popup_close_tick, wait / 100)
	}
	// Close immediately
	else {
		// Hide popup
		$('#popup').modal('hide')

		// Run callback
		if (typeof popup_close_callback === 'function') {
			popup_close_callback()
		}
	}
}

/**
 * Set popup closing options
 * @param {boolean} click - Close if clicked outside of popup window
 * @param {boolean} press - Close if ESC key is pressed
 * @returns {boolean} - Always false
 */
function popup_autoclose(click, press) {
	var modal = $('#popup')

	// Detect click behaviour
	if (typeof click === 'undefined') {
		click = true
	}
	autoclose.click = parseBool(click)

	// Detect keypress behaviour
	if (typeof press === 'undefined') {
		press = true
	}
	autoclose.press = parseBool(press)

	// Set params if already initialized
	if (typeof modal.data('bs.modal') === 'undefined') {
		modal.modal({
			backdrop: autoclose.click ? true : 'static',
			keyboard: autoclose.press
		})
	}
	else {
		modal.data('bs.modal').options.backdrop = autoclose.click ? true : 'static'
		modal.data('bs.modal').options.keyboard = autoclose.press

		if (autoclose.press === false) {
			// Disable ESC
			modal.off('keydown.dismiss.bs.modal')
		}
		else {
			// Use ESC
			modal.data('bs.modal').escape()
		}
	}
}

/**
 * Scheduled popup closing updater
 */
function popup_close_tick() {
	closer_ticks++

	if (closer_ticks > 100) {
		clearInterval(closer_timer)
		closer_timer = null
		popup_close(0, popup_close_callback)
	}

	$('#popup-close-progress').width((100 - closer_ticks) + '%')
}

/**
 * Replaces standard alert() dialogs. Also can provide a loading spinner popup
 * @param {string} title - Text in the popup header
 * @param {string} text - Modal body text
 * @param {function} callback - Function to call when popup is closed with close button
 */
function mbox(title, text, callback) {
	var ok_button

	if (text === 'spinner') {
		text = '<p class="text-center p-t-2 p-b-2">' + icon('fa fa-2x fa-sync fa-spin fa-fw') + '</p>'
	}

	ok_button = {
		close: true,
		icon: 'fa-regular fa-circle-xmark',
		class: 'btn-default',
		text: 'Close'
	}
	if (typeof callback === 'function') {
		mbox_callback = callback
		ok_button['click'] = 'mbox_callback()'
	}

	popup_width(520)
	popup_buttons([ ok_button ])
	$('#popup-title').html(title)
	$('#popup-body').html('<div class="mbox">' + text + '</div>')
	popup_show()
}

/**
 * Replaces standard confirm() dialogs
 * @param {string} title - Text in the popup header
 * @param {string} text - Modal body text
 * @param {function} yes - function to run when accepted
 * @param {function} no - function to run when dismissed (closes popup by default)
 */
function confirm_box(title, text, yes, no) {
	// Set custom ACCEPT handler
	if (typeof yes === 'function') {
		confirm_callback_yes = yes
	}
	// Don't do anything by default
	else {
		confirm_callback_yes = null
	}

	// Set custom NO handler
	if (typeof no === 'function') {
		confirm_callback_no = no
	}
	// Close popup by default
	else {
		confirm_callback_no = popup_close
	}

	popup_width(460)
	$('#popup-title').html(title)
	$('#popup-body').html('<div class="popup-confirm">' + text + '</div>')
	popup_buttons([
		{
			click: 'confirm_callback(false)',
			close: true,
			icon: 'fa-regular fa-circle-xmark',
			class: 'btn-default',
			text: 'No'
		},
		{
			click: 'confirm_callback(true)',
			close: false,
			icon: 'fa-regular fa-circle-check',
			class: 'btn-danger',
			text: 'Yes'
		}
	])

	popup_show()
}

/**
 * Runs the confirm popup response
 * @param {boolean} accepted - User accepted or refused
 */
function confirm_callback(accepted) {
	if (accepted === true && typeof confirm_callback_yes === 'function') {
		confirm_callback_yes()
	}
	else if (typeof confirm_callback_no === 'function') {
		confirm_callback_no()
	}
}

var popup_element

$(function() {
	popup_element = new bootstrap.Modal(document.getElementById('popup'))
})
