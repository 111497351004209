/**
 * Template Name: BizLand - v3.7.0
 * Template URL: https://bootstrapmade.com/bizland-bootstrap-business-template/
 * Author: BootstrapMade.com
 * License: https://bootstrapmade.com/license/
 */

$(function() {
	/**
	 * Easy selector helper function
	 */
	const select = (el, all = false) => {
		el = el.trim()
		if (all) {
			return [ ...document.querySelectorAll(el) ]
		}
		else {
			return document.querySelector(el)
	}
	}

	/**
	 * Easy on scroll event listener
	 */
	const onscroll = (el, listener) => {
		el.addEventListener('scroll', listener)
	}

	/**
	 * Navbar links active state on scroll
	 */
	let navbarlinks = select('#navbar .scrollto', true)

	const navbarlinksActive = () => {
		let position = window.scrollY + 200
		navbarlinks.forEach(navbarlink => {
			if (!navbarlink.hash) {
				return
			}
			let section = select(navbarlink.hash)
			if (!section) {
				return
			}
			if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
				navbarlink.classList.add('active')
			}
			else {
				navbarlink.classList.remove('active')
			}
		})
	}
	window.addEventListener('load', navbarlinksActive)
	onscroll(document, navbarlinksActive)

	/**
	 * Scrolls to an element with header offset
	 */
	const scrollto = (el) => {
		let header = select('#header')
		let offset = header.offsetHeight

		if (!header.classList.contains('header-scrolled')) {
			offset -= 16
		}

		let elementPos = select(el).offsetTop
		window.scrollTo({
			top: elementPos - offset,
			behavior: 'smooth'
		})
	}

	/**
	 * Header fixed top on scroll
	 */
	let site_header = document.getElementById('header')

	if (site_header) {
		let headerOffset = site_header.offsetTop
		let nextElement = site_header.nextElementSibling

		const headerFixed = () => {
			if ((headerOffset - window.scrollY) <= 0) {
				site_header.classList.add('fixed-top')
				nextElement.classList.add('scrolled-offset')
			}
			else {
				site_header.classList.remove('fixed-top')
				nextElement.classList.remove('scrolled-offset')
			}
		}
		window.addEventListener('load', headerFixed)
		onscroll(document, headerFixed)
	}

	/**
	 * Back to top button
	 */
	let backtotop = $('.back-to-top')

	if (backtotop.length > 0) {
		const toggleBacktotop = () => {
			if (window.scrollY > 100) {
				backtotop.addClass('active')
			}
			else {
				backtotop.removeClass('active')
			}
		}
		window.addEventListener('load', toggleBacktotop)
		onscroll(document, toggleBacktotop)

		backtotop.on('click', function(event) {
			event.preventDefault()
			$('html, body').animate({ scrollTop: 0 }, 220)

			return false
		})
	}

	/**
	 * Mobile nav toggle
	 */
	$('.mobile-nav-toggle').on('click', function(e) {
		select('#navbar').classList.toggle('navbar-mobile')
		this.classList.toggle('bi-list')
		this.classList.toggle('bi-x')
	})

	/**
	 * Mobile nav dropdowns activate
	 */
	$('.navbar .dropdown > a').on('click', function(e) {
		if (select('#navbar').classList.contains('navbar-mobile')) {
			e.preventDefault()
			this.nextElementSibling.classList.toggle('dropdown-active')
		}
	})

	/**
	 * Preloader
	 */
	if ($('#preloader').length > 0) {
		preloader.remove()
	}

	/**
	 * Animation on scroll
	 */
	AOS.init({
		duration: 1000,
		easing: 'ease-in-out',
		once: true,
		mirror: false
	})

	// Links available only to subscribed users
	$('.click-login-alert').on('click', function() {
		mbox('Subscription needed', 'This page is for subscribed users only. You need to login first and have a valid subscription or trial period to access this page!')

		return false
	})

	// Non-clickable link items
	$('.click-none').on('click', function() {
		return false
	})

	// Show TOS in a popup
	$('.view-tos').on('click', function() {
		popup_buttons([ {
				close: true,
				icon: 'fas fa-times',
				class: 'btn-default',
				text: 'Close'
			} ])
		popup_width(800)
		popup('Terms of services <small>Effective as of [04.12.22]</small>', 'terms-of-service-popup')

		return false
	})
})



// Registration page
$(function() {
	if ($('.page-register').length < 1) {
		return
	}

	//$('#country').select2()
})


// Tools page - Comparator & Realsync
$(function() {
	if ($('.page-tools').length < 1) {
		return
	}

	var drop_old = new Dropzone('#form-old', {
		headers: {
			'X-CSRF-TOKEN': csrf_token
		},
		url: base_url + 'tools/upload',
		acceptedFiles: $('#form-old').attr('data-accept'),
		maxFilesize: 4, // 1 MiB
		maxFiles: 1, // Maximum number of files
		createImageThumbnails: false,
		addRemoveLinks: true
	})

	var drop_new = new Dropzone('#form-new', {
		headers: {
			'X-CSRF-TOKEN': csrf_token
		},
		url: base_url + 'tools/upload',
		acceptedFiles: $('#form-new').attr('data-accept'),
		maxFilesize: 4, // 1 MiB
		maxFiles: 1, // Maximum number of files
		createImageThumbnails: false,
		addRemoveLinks: true
	})

	$('#form-create').on('submit', function() {
		var has_old = drop_old.files.length > 0
		var has_new = drop_new.files.length > 0
		var has_name = $('#report-name').val().trim().length > 0
		var error = false

		if (!has_old) {
			error = 'No old EDL file uploaded!'
		}
		else if (!has_new) {
			error = 'No new EDL file uploaded!'
		}
		else if (!has_name) {
			error = 'No name set for the report!'
		}

		if (error) {
			mbox('Error', error)

			return false
		}

		return true
	})
})


// Tools page - EDL2TXT

function runEdl2Txt() {
	$('#form-create').submit()
}

$(function() {
	if ($('.page-tools-edl2txt').length < 1) {
		return
	}

	var drop_edl = new Dropzone('#form-edl', {
		headers: {
			'X-CSRF-TOKEN': csrf_token
		},
		url: base_url + 'tools/upload',
		acceptedFiles: $('#form-edl').attr('data-accept'),
		maxFilesize: 4, // 1 MiB
		maxFiles: 1, // Maximum number of files
		createImageThumbnails: false,
		addRemoveLinks: true
	})

	sortable('#column-list', {
		placeholderClass: 'list-group-item list-group-item-secondary',
		itemSerializer: (serializedItem, sortableContainer) => {
			return $(serializedItem.node).attr('data-key')
		}
	})

	$('#form-create').on('submit', function() {
		let has_edl = drop_edl.files.length > 0
		let has_name = $('#report-name').val().trim().length > 0
		let columns = sortable('#column-list', 'serialize')[0].items
		let error = false

		if (!has_edl) {
			//error = 'No EDL file uploaded!'
		}
		else if (!has_name) {
			error = 'No name set for the report!'
		}

		if (error) {
			mbox('Error', error)

			return false
		}

		// Store column order
		$('#column-order').val(JSON.stringify(columns))

		return true
	})
})


// Reports page
$(function() {
	if ($('.page-reports').length < 1) {
		return
	}

	$('.view-report').on('click', function() {
		popup_width(1290)
		popup('View report', $(this).attr('href'))

		return false
	})

	$('.view-errors').on('click', function() {
		popup_width(1290)
		popup('View erros & warnings', $(this).attr('href'))

		return false
	})
})


// Subscription page

var pp = {
	token: '',
	currency: '',
	total: 0.0
}

function initCheckout() {
	popup_width(520)
	popup_buttons([ {
			close: true,
			icon: 'fa-solid fa-times',
			class: 'btn-default',
			text: 'Cancel'
		} ])
	popup(
		'Checkout',
		'checkout',
		null,
		function() {
			$.ajax({
				url: base_url + 'checkout/params',
				method: 'post',
				data: {
					package: $('#package-id').val()
				},
				dataType: 'json',
				success: function(data) {
					pp.currency = data.currency
					pp.total = data.total
					$('#total-price').text('$' + data.total)

					paypal.Button.render({
						env: data.env,
						client: {
							sandbox: data.sandbox_key,
							production: data.production_key
						},
						commit: true, // Show a 'Pay Now' button
						style: {
							color: 'blue',
							size: 'large'
						},
						payment: function(data, actions) {
							return actions.payment.create({
								payment: {
									transactions: [ {
											amount: {
												total: pp.total,
												currency: pp.currency
											}
										} ]
								}
							})
						},
						onAuthorize: function(data, actions) {
							pp.token = data.paymentToken

							return actions.payment.execute().then(function(payment) {
								popup_buttons([ {
										close: true,
										icon: 'fa-regular fa-circle-check',
										class: 'btn-default',
										text: 'Ok',
										click: "go('')"
									} ])
								popup('Subscription Payment', base_url + 'checkout/done', { data: payment, token: pp.token })
							})
						}
					}, '#paypal-button')
				},
				error: function(data) {
					console.error(data)
				}
			})
		}
	)
}

$(function() {
	if ($('.page-subscription').length < 1) {
		return
	}

	$('.package-selector').on('click', function() {
		let package = $(this).attr('data-package')

		$('.package-title').removeClass('featured')
		$('#package-title-' + package).addClass('featured')
		$('#package-id').val($(this).attr('data-package'))
		$('#proceed-pay-button').prop('disabled', false)

		return false
	})

	$('#subscription-form').on('submit', function() {
		initCheckout()

		return false
	})
})
